import { useEffect, useRef, useState } from 'react';

export function useMousePosition() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener('mousemove', setFromEvent);
    return () => {
      window.removeEventListener('mousemove', setFromEvent);
    };
  }, []);
  return position;
}

export function useTouchPosition() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    window.addEventListener('touchmove', setFromEvent);
    return () => {
      window.removeEventListener('touchmove', setFromEvent);
    };
  }, []);
  return position;
}

export default function useCursorPosition(size) {
  const mousePos = useRef({ x: 0, y: 0 });
  const touchPos = useRef({ x: 0, y: 0 });
  const cursorPos = useRef({ x: 0, y: 0 });
  const newMousePos = useMousePosition();
  const newTouchPos = useTouchPosition();
  if (mousePos.current.x !== newMousePos.x
    || mousePos.current.y !== newMousePos.y) {
    mousePos.current = newMousePos;
    cursorPos.current = {
      x: newMousePos.x - size / 2,
      y: newMousePos.y - size / 2,
    };
  } else if (touchPos.current.x !== newTouchPos.x
    || touchPos.current.y !== newTouchPos.y) {
    touchPos.current = newTouchPos;
    cursorPos.current = {
      x: newTouchPos.x - size / 2,
      y: newTouchPos.y - size / 2,
    };
  }

  return cursorPos.current;
}
