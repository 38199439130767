export const breakpoints = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
};

export const zIndex = {
  cursor: 10,
};

export default {
  breakpoints,
  zIndex,
};
