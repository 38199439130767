import { css, createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';
import { breakpoints } from './variables';
import 'typeface-lora';

export const respondTo = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {},
);

export default createGlobalStyle`
  ${reset}

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    color: #424242;
    ${'' /* cursor: none; */}
    font-family: 'Lora', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    transition: color .3s;

    &,
    &:visited {
      color: #424242;
    }

    @supports (mix-blend-mode: difference) {
      &:hover {
        color: #eee;
      }
    }
  }

  p {
    font-size: 15px;
    line-height: 1.57;

    & + & {
      margin-top: 1.5em;
    }
  }
`;
