import React, {
  useCallback, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CursorDot from './cursorDot';
import useCursorPosition from '../hooks/cursorPosition';
import useRaf from '../hooks/raf';
import { zIndex } from '../globals/variables';

const CursorWrapper = styled.div`
  height: 100%;
  left: 0;
  mix-blend-mode: difference;
  pointer-events: none;
  position: fixed;
  top: 0;
  z-index: ${zIndex.cursor};
  width: 100%;
`;

const size = 22;

const Cursor = (props) => {
  const { amount, trail } = props;

  const idle = useRef(false);
  const idleTimeout = useRef(null);
  let timeoutCleared = false;

  const moved = useRef(false);

  // Check for cursor position updates
  const cursorPos = useRef({ x: 0, y: 0 });
  const newCursorPos = useCursorPosition(size);
  if (cursorPos.current.x !== newCursorPos.x
    || cursorPos.current.y !== newCursorPos.y) {
    cursorPos.current = { ...newCursorPos };
    idle.current = false;
    clearTimeout(idleTimeout.current);
    timeoutCleared = true;
    moved.current = true;
  }

  const sineDots = Math.floor(amount * 0.3);
  const initDotArray = () => {
    const array = [];
    for (let index = 0; index < amount; index += 1) {
      const scale = 1 - (1 / amount) * index;
      const dot = {
        index,
        isPrimary: index <= sineDots,
        angle: {
          x: Math.PI * 2 * Math.random(),
          y: Math.PI * 2 * Math.random(),
        },
        lockPosition: {
          x: 0,
          y: 0,
        },
        position: {
          x: 0,
          y: 0,
        },
        range: size / 2 - (size / 2) * scale + 2,
        scale,
      };
      array.push(dot);
    }
    return array;
  };
  const [timestamp, setTimestamp] = useState(0);
  const [delta, setDelta] = useState(0);

  const cursorDots = useRef();
  if (!cursorDots.current) {
    cursorDots.current = initDotArray();
  }

  useRaf(
    useCallback((deltaTime, time) => {
      setDelta(deltaTime);
      setTimestamp(time);
    }, []),
  );

  useLayoutEffect(() => {
    const anglespeed = 0.01;
    const currentPos = { ...cursorPos.current };
    cursorDots.current = cursorDots.current.map((dot, index, dots) => {
      const nextDot = dots[index + 1] || dots[0];
      const updates = {};
      updates.position = { ...currentPos };

      if (!idle.current || dot.isPrimary) {
        const dx = (nextDot.position.x - updates.position.x) * trail;
        const dy = (nextDot.position.y - updates.position.y) * trail;
        currentPos.x += dx;
        currentPos.y += dy;
      } else {
        updates.angle = {};
        updates.angle.x = dot.angle.x + anglespeed;
        updates.angle.y = dot.angle.y + anglespeed;
        updates.position.x = dot.lockPosition.x + Math.sin(dot.angle.x) * dot.range;
        updates.position.y = dot.lockPosition.y + Math.sin(dot.angle.y) * dot.range;
      }

      return { ...dot, ...updates };
    });
  }, [delta, timestamp, trail]);

  if (timeoutCleared) {
    idleTimeout.current = setTimeout(() => {
      idle.current = true;
      cursorDots.current = cursorDots.current.map((dot) => {
        const updates = {
          lockPosition: { ...dot.position },
          angle: {
            x: Math.PI * 2 * Math.random(),
            y: Math.PI * 2 * Math.random(),
          },
        };
        return { ...dot, ...updates };
      });
    }, 150);
  }

  return (
    <CursorWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="100%" width="100%">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="6" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 35 -15"
              result="goo"
            />
            {/* <feComposite in="SourceGraphic" in2="goo" operator="atop" /> */}
          </filter>
        </defs>
        <g filter="url(#goo)">
          <rect x="0" y="0" width="100%" height="100%" fill="none" stroke="none" />
          {cursorDots.current.map((dot) => (
            <CursorDot
              position={dot.position}
              key={dot.index}
              scale={dot.scale}
              size={size}
              visible={moved.current}
            />
          ))}
        </g>
      </svg>
    </CursorWrapper>
  );
};

Cursor.propTypes = {
  amount: PropTypes.number,
  trail: PropTypes.number,
};

Cursor.defaultProps = {
  amount: 20,
  trail: 0.35,
};

export default Cursor;
