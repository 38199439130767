import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCursorDot = styled.circle`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  overflow: hidden;
  pointer-events: none;
  stroke: none;
  transform-origin: center;
`;

const CursorDot = (props) => {
  const {
    position, size, scale, visible,
  } = props;

  return (
    <StyledCursorDot
      size={size}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
      }}
      visible={visible}
      cx={(size * scale) / 2}
      cy={(size * scale) / 2}
      fill="#fff78c"
      r={size * scale}
    />
  );
};

CursorDot.propTypes = {
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  scale: PropTypes.number,
  size: PropTypes.number,
  visible: PropTypes.bool,
};

CursorDot.defaultProps = {
  scale: 1,
  size: 26,
  visible: false,
};

export default CursorDot;
