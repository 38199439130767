/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Cursor from './cursor';
import GlobalStyle from '../globals/globalStyles';

const Main = styled.main`
  isolation: isolate;
`;

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <Main>
      {children}
      <Cursor amount={10} trail={0.45} />
    </Main>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
